
.navbar {
    background-color: #FDFDFD;
    border-bottom: 1px solid #e0e0e0;
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0px;
    --bs-nav-link-font-size: 14px;
    --bs-nav-link-color: #333333;
    --bs-navbar-nav-link-padding-x: 12px;
}

.nav-item {
    margin-left: 4px;
    margin-right: 4px;
}

@media (min-width: 992px) {
    .navbar {
        --bs-navbar-padding-x: 16px;
        --bs-navbar-padding-y: 0;
    }

    .navbar-brand {
        margin-right: 100px !important;
    }
}
